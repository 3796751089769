@import '../../../theme/newVariables';

.overflowGradient {
  @media (min-width: $large-screen-max) {
    height: $az-spacing-135;
    background: linear-gradient(
      to right,
      $az-grey-6 $az-percent-zero,
      $az-grey-6 $az-percent-30,
      transparent $az-percent-30,
      transparent $az-percent-100
    );
  }
}

.overflowChildren {
  max-width: $az-spacing-1600;
  margin: $az-spacing-zero auto;
}

.arrowTablet {
  height: $az-spacing-xxl;
  width: $az-spacing-xxl;
  border: $az-spacing-1 solid $az-black;
  border-radius: $az-spacing-zero;
  background-color: $az-black;
  font-size: $font-size-14;
  font-weight: 600;
  margin-top: $az-spacing-zero;
  min-width: $az-spacing-30;
  padding: $az-spacing-zero;

  &::before {
    content: url('/images/footer/arrow-right.svg');
    display: inline-block;
  }

  @media #{$small-and-down} {
    float: right;
  }
}

.loginSection {
  padding: $az-spacing-s $az-spacing-rem-2;
  background: $az-grey-6;

  @media #{$small-and-down} {
    background-size: cover;
  }

  @media #{$extra-large-and-up-max} {
    padding: $az-spacing-s $az-spacing-rem-14;
  }

  .elevateItem {
    z-index: 1;
  }

  .envelopeIcon {
    padding-right: $az-spacing-10;
    box-sizing: content-box;
  }

  .heading {
    align-items: center;
    justify-content: center;
    white-space: nowrap;

    @media #{$small-and-down} {
      margin-left: auto;
      margin-right: auto;
      padding-top: $az-spacing-s;
      font-size: $font-size-24;
    }

    @media #{$medium-only} {
      padding-top: $az-spacing-xxs;
      font-size: $font-size-24;
    }

    @media #{$extra-large-and-up} {
      font-size: $font-size-24;
    }
  }

  .subHeading {
    @media #{$small-and-down} {
      padding-bottom: $az-spacing-s;
      padding-left: $az-spacing-xxs;
      padding-right: $az-spacing-xxs;
      font-size: $font-size-14;
    }

    @media #{$medium-only} {
      font-size: $font-size-14;
      width: $az-percent-60;
    }

    @media #{$extra-large-and-up} {
      position: relative;
      width: $az-percent-101;
      padding-left: $az-spacing-4xs;
    }
  }

  .speedBar {
    @media #{$medium-and-up} {
      position: absolute;
      top: $az-spacing-4xs;
      right: $az-spacing-neg-rem-3;
      width: $az-spacing-196;
      height: $az-spacing-106;
      z-index: 2;
    }

    @media #{$small-and-down} {
      position: absolute;
      top: $az-spacing-neg-22;
      right: $az-spacing-23;
      width: $az-spacing-420;
      height: $az-spacing-234;
      z-index: 1;
    }
  }

  .leftContentGray {
    color: $az-white;
    position: relative;
    align-self: center;

    @media #{$small-and-down} {
      text-align: center;
      align-items: center;
      justify-content: center;
    }

    @media #{$medium-only} {
      background-color: $az-grey-6;
    }

    @media #{$extra-large-and-up} {
      background-color: $az-grey-6;
    }
  }

  .leftContent {
    @media #{$small-and-down} {
      text-align: center;
      align-items: center;
      justify-content: center;
      display: block;
    }
  }

  .loginHolderGray {
    display: flex;
    flex-direction: row;
    max-width: $az-spacing-331;
    justify-content: flex-end;

    @media #{$small-and-down} {
      padding-bottom: $az-spacing-s;
      max-width: none;
      justify-content: center;
    }

    @media #{$medium-and-up} {
      width: $az-percent-100;
      position: relative;
      align-self: center;
      justify-content: flex-end;
    }

    @media #{$medium-only} {
      padding-right: $az-percent-2-25;
    }
  }

  h4 {
    color: $az-white;

    @media #{$small-and-down} {
      text-align: center;
    }
  }

  .textBoxStyle {
    height: $az-spacing-xxl;
    background-color: $az-white;
    width: auto;
  }

  .validateBtn {
    width: $az-percent-140;
    height: $az-spacing-xxl;
    background-color: $az-black;

    &:focus {
      background-color: $az-black;
      padding-bottom: $az-spacing-zero;
    }

    @media #{$extra-large-and-up} {
      width: $az-spacing-xxl;
      height: $az-spacing-xxl;
    }
  }

  button:focus {
    background-color: $az-black;
  }
}

.emailErrorStyleImportant.emailErrorStyle {
  color: $az-white !important;
}

.errorLabelStyle {
  color: $az-black !important;
}

.inputErrorStyle {
  height: $az-spacing-xxl;
  background-color: $az-white;
  box-shadow: inset $az-spacing-zero $az-spacing-neg-3 $az-spacing-zero $az-spacing-zero
    $az-color-error;
  width: auto;
}

.loginButton {
  height: $az-percent-100;
}

.speedDivider {
  width: $az-percent-100;
  padding: $az-spacing-zero $az-percent-2-25;
  position: relative;
  top: $az-spacing-neg-2;
  background: linear-gradient(to right, $az-grey-6 $az-percent-60, transparent $az-percent-40);
  vertical-align: text-top;
}

.successButton {
  color: $az-color-success;
  border-color: $az-color-success;
  width: $az-percent-73;
  height: $az-spacing-xxl;

  &::before {
    content: url(/images/large.svg);
    display: inline-block;
    padding-right: $az-spacing-4xs;
  }
}

.successDiv {
  @media #{$small-and-down} {
    display: flex;
    align-items: center;
    background-color: $az-white;
    width: $az-percent-100;
    height: $az-spacing-xxl;
  }
}

.successMsgContainer {
  height: $az-percent-100;

  @media #{$small-and-down} {
    color: $az-color-success;
  }

  @media #{$medium-only} {
    margin-left: $az-percent-30;
  }
}

.successMsgIcon {
  margin-right: $az-spacing-6;
}

.successMsg {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  flex: 1;

  @media #{$small-and-down} {
    margin-left: auto;
    margin-right: auto;
    font-size: $font-size-24;
  }

  @media #{$medium-only} {
    padding-top: $az-spacing-xxs;
    font-size: $font-size-24;
  }

  @media #{$extra-large-and-up} {
    font-size: $font-size-24;
  }
}

.successTick {
  height: $az-spacing-15;
}

.successTickContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: $az-percent-100;

  @media #{$small-and-down} {
    float: right;
    padding-right: $az-spacing-26;
  }

  @media #{$medium-only} {
    background-color: $az-color-success;
    height: $az-spacing-xxl;
    width: $az-spacing-xxl;
  }
}

@media print {
  .loginSection {
    display: none;
  }
}

.textFieldContainer {
  max-width: $az-spacing-275;
  justify-content: flex-end;

  @media #{$small-and-down} {
    justify-content: center;
  }
}
