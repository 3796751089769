@import '../../../../theme/breakPoints';
@import '../../../../theme/colors.module';
@import '../../../../theme/fontFamilies';
@import '../../../../theme/spacingSizes';

.itemContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: $az-spacing-66;

  @media #{$small-and-down} {
    justify-content: flex-start;
    height: $az-spacing-104;
  }

  @media #{$extra-large-and-up} {
    height: $az-spacing-100;
  }

  @media #{$extra-large-and-up-max} {
    height: $az-spacing-86;
  }
}

.image {
  width: $az-spacing-60;
  height: $az-spacing-60;
  object-fit: contain;
  object-position: center;
}

.descriptionContainer {
  margin-left: $az-spacing-4xs;

  @media #{$extra-large-and-up} {
    margin-left: $az-spacing-s;
  }

  @media #{$small-and-down} {
    margin-left: $az-spacing-xs;
  }
}

.title {
  color: $az-black;
  font-size: $az-spacing-14;
  font-family: $font-az-cond-heavy;
  text-transform: uppercase;

  @media #{$extra-large-and-up} {
    color: $az-black;
    font-size: $az-spacing-xs;
    letter-spacing: $az-spacing-three-fourths;
    line-height: $az-spacing-25;
  }

  @media #{$small-and-down} {
    font-size: $az-spacing-xxs;
    letter-spacing: $az-spacing-point-six;
    line-height: $az-spacing-xs;
  }
}

.subTitle {
  color: $az-black;
  font-family: $font-az-helvetica;
  font-size: $az-spacing-xxxs;
  letter-spacing: $az-spacing-point-four;
  line-height: $az-spacing-xxs;

  @media #{$extra-large-and-up} {
    color: $az-black;
    font-size: $az-spacing-xxs;
    line-height: $az-spacing-s;
  }

  @media #{$small-and-down} {
    color: $az-grey-6;
    font-size: $az-spacing-14;
    line-height: $az-spacing-xs;
  }
}
