@import '@/theme/newVariables';

.cardsContainer#citrusCarousel {
  padding-top: var(--st-unit-3);
  padding-bottom: var(--st-unit-1);
  margin-top: var(--st-unit-2);
  margin-bottom: calc(var(--st-unit-1) * -1);
  min-height: initial;

  @media #{$medium-and-up} {
    padding-bottom: var(--st-unit-4);
    margin-top: var(--st-unit-3);
    margin-bottom: calc(var(--st-unit-3) * -1);
  }
}
