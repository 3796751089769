//  Table of Contents:
//  1. Media Query Screen width
//  2. General Style
//  3. Colors
//  4. Font Style
//  5. Box Shadow Style

// 1.Media Query Screen width
@import './breakPoints';

//  2. General Style
@import './alignments.scss';

//  3. Colors
$color-white: #eee;
$color-white-main: #fff;
$az-white: #f7f7f5;
$color-lighter-grey: #e5e5e5;
$color-light-gray: #f2f2f2;
$white-color: white;
$white-field-color: #ccc;
$white-light-grey-color: #f4f4f4;
$crust-white-color: #c4c4c4;
$az-ultra-white: #f9f9f9;
$az-ultra-gray: lightgray;
$az-aqua-white: #ddd;
$az-ultra-plain-white: #d0d0d0;
$az-ultra-crust-white: #f6f6f6;
$az-ultra-aq-white: #bbb;
$az-light-white: #f8f8f8;
$az-white-aqua: #c3c3c3;
$az-white-grad: #dadada;
$az-light-grey-color: #aaa;
$color-grey: #a9aaa8;
$gray-color: gray;
$az-light-grey: #787878;
$color-light-grey: #ece9e9;
$autozone-grey: #666;
$gray-bg-color: #5d5d5d;
$grey-ultra-dark: #484848;
$az-aq-grad-grey: #767676;
$az-ultra-light-grey: #4b4b4b;
$az-ultra-crust: #323232;
$az-ultra-crust-gray: #383838;
$az-ultra-grey: #595959;
$az-grey-grad: #b3b3b3;
$az-aqua-grey: #999;
$az-gray-shine: #9b9b9b;
$color-dark-gray: #333;
$az-crust-grey: #5b5d5b;
$az-grad-gray: #5b5c5d;
$az-grey-crust: #878787;
$az-grey-light: #f2f2f2;
$az-light-crust-grey: #5b5b5b;
$az-dark-grey: #3a3c3d;
$az-warm-grey: #7c7c7c;
$az-color-light-grey: #7a7a7a;
$az-grey-asparagus: #a4a6a4;
$az-checkout-light-grey: #eee;
$az-crust-black: #231f20;
$az-ultra-dark: #222;
$autozone-red: #cf1322;
$red-color: red;
$az-error-color: #f00;
$az-dark-red: #f10d15;
$az-grad-red: #d52b1e;
$orange-primary: #f26100;
$orange-secondary: #f28100;
$az-yellow: #f0ad4e;
$az-yellow-light: #ffc600;
$az-ultra-yellow: #ff9800;
$az-dark-yellow: #f38021;
$az-orange-dark: #d65600;
$color-green: #237804;
$az-green: green;
$az-ultra-green: #2c7800;
$az-light-green: #417505;
$az-dark-green: #20633b;
$az-grass-green: #1f7400;
$az-bright-magenta: #ff00d8;
$az-warm-pink: #ff4081;
$az-vivid-purple: #8b00ff;
$az_azure: #03a9f4;
$az-blue-colour: #2196f3;
$az-ultra-blue: #3b82bf;
$az-dark-blue: #0f4b91;
$black-color: black;
$black-bg-color: #000;
$az-black-color: #111;
$az-light-black: #202124;
$az-para-black: rgba(0, 0, 0, 0.87);
$az-grad-black: rgba(0, 0, 0, 0.8);
$az-orange-primary: #f26100;
$az-warm-grey: #302e2e;
$az-dark-grey-text: #4a4a4a;
$az-pipe-grey-text: #979797;
$az-snow-drift-grey: #d9d9d8;
$az-white-selection: #f7f7f7;
$az-cream-color: #a9aaa8;
$az-burnt-red: #ab0b0b;
$az-black-dark: #625a5b;
$az-grey-dark: #333232;
$az-pipe-grey: #929292;
$az-light-gray: rgba(0, 0, 0, 0.4);
$az-lighter-gray: rgba(0, 0, 0, 0.2);
$az-lighter-gray: rgba(0, 0, 0, 0.1);
$az-lightest-gray: rgba(0, 0, 0, 0.15);
$az-lighter-gray-tone: rgba(0, 0, 0, 0.2);
$color-lighter-gray-tone: rgba(0, 0, 0, 0.14);
$color-lightest-grey-tone: rgba(0, 0, 0, 0.12);
$color-highlight-white: rgba(0, 0, 0, 0);
$az-gray-black: rgba(255, 255, 255, 0.2);
$color-dark-gray: #6d6d6d;
$color-grey-light: #d8d8d8;
$color-grey-highlight: #878787;
$color-orange-light: #f37f00;
$color-orange-dark: #e96100;
$color-grey-light-grey: #f3f3f2;
$color-black-light: #444;
$color-darker-blue: #0050b3;
$color-gainsboro: #e0e0e0;
$color-whisper: #e9e9e9;
$color-silver: #c6c6c6;
$az-silver-grey: #7c7c7c;
$az-white-smoke: #f5f5f5;
$color-light-silver: rgba(242, 242, 242, 0.4);
$color-light-black-gray: #171717;
$color-green-azure: #2ab7a9;

//  4. Font Style
//  Font style Autozone
$font-az: Helvetica Neue, Arial, sans-serif;
$font-az-header: 'HelveticaNeue', 'Helvetica', Arial, sans-serif;
$font-az-medium: 'AutoZoneCond-Medium', Arial, sans-serif;
$font-az-vehicle: 'AutoZoneCond-Medium', Helvetica Neue, Arial, sans-serif;
$font-az-cond: 'AutoZoneCond', Helvetica, sans-serif;
$font-az-cond-heavy: 'AutoZoneCond-Heavy', Arial, sans-serif;
$font-az-app: 'HelveticaNeue', sans-serif;
$font-category-cont: 'HelveticaNeue-Medium', sans-serif;
$font-category-cont-heavy: 'HelveticaNeue-Bold', sans-serif;
$font-az-app-light: Helvetica Neue, 'Helvetica-Light', 'Helvetica', Arial, sans-serif;
$font-az-acct: 'AutoZoneCond', Helvetica Neue, Arial, sans-serif;
$font-az-container: 'AutoZoneCond', Arial, sans-serif;
$font-az-light-container: 'AutoZoneCond', sans-serif;
$font-az-big-container: 'AutoZoneCond-Heavy', sans-serif;
$font-prod-style: 'Roboto', sans-serif;

//  5. Box Shadow Style
//  box-shadow
$box-shadow-orange: 1px 2px 4px 0 rgba(0, 0, 0, 0.2), inset 4px 0 0 0 $orange-primary;
$az-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.06),
  0 2px 4px 0 rgba(0, 0, 0, 0.07);
$box-shadow-ultra-grey: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12),
  0 0 2px 0 rgba(0, 0, 0, 0.14);
$box-shadow-grey: 1px 2px 4px 0 rgba(0, 0, 0, 0.2), inset 4px 0 0 0 $color-grey;
$box-shadow-black: 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
$box-shadow-ultra-dark: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
$box-shadow-pick: -3px 2px 4px 0 rgba(0, 0, 0, 0.1), inset 1px 0 0 0 $color-grey,
  inset -1px 0 0 0 $color-grey, inset 0 1px 0 0 $color-grey inset 0 -4px 0 0 $black-bg-color;
$box-shadow-gray-top: 0 -2px 4px 0 rgba(0, 0, 0, 0.2);
$box-shadow-grey-light: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
$box-shadow-dark: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
$box-shadow-azblack: 0 1px 4px 0 rgba(0, 0, 0, 0.12);
$box-shadow-dark-black: 0 0 2px 0 rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.12),
  0 1px 3px 0 rgba(0, 0, 0, 0.2), inset 4px 0 0 0 $az-grad-red;
$box-shadow-dark-grey: 1px 1px 8px 0 rgba(0, 0, 0, 0.5);
$box-shadow-yellow: 0 0 2px 0 rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.12),
  0 1px 3px 0 rgba(0, 0, 0, 0.2), inset 4px 0 0 0 $az-yellow-light;
$box-shadow-blue: 0 0 2px 0 rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.12),
  0 1px 3px 0 rgba(0, 0, 0, 0.2), inset 4px 0 0 0 $az-ultra-blue;
