@import '@/theme/newVariables';

$hero-img-size: 336px;

.heroContainer {
  @media #{$STARC-medium-and-up} {
    max-width: $large-screen-max;
    margin: 0 auto 10px;
    padding-right: var(--st-unit-4);
    padding-left: var(--st-unit-4);
  }

  @media #{$extra-large-and-up} {
    padding-right: var(--st-unit-5);
    padding-left: var(--st-unit-5);
  }

  .slider {
    width: 100%;
    text-align: left;
    overflow: hidden;
    position: relative;
    margin: 0;
    padding-left: 0;
    max-width: $large-screen-max;
    height: 100%;

    @media #{$STARC-medium-and-up} {
      padding-left: inherit;
      max-height: $hero-img-size;
      height: $hero-img-size;
    }

    @media #{$large-and-up} {
      max-height: 100%;
      height: 100%;
    }

    @media #{$extra-large-and-up} {
      max-width: 100%;
      height: inherit;
    }

    & > a {
      display: inline-flex;
      width: 1.5rem;
      height: 1.5rem;
      background: $az-white;
      text-decoration: none;
      align-items: center;
      justify-content: center;
      border-radius: $az-percent-50;
      margin: $az-spacing-zero $az-spacing-zero 0.5rem $az-spacing-zero;
      position: relative;

      &:active {
        top: $az-spacing-1;
      }

      &:focus {
        background: $az-black;
      }
    }
  }

  .featuredContainer {
    justify-content: space-between;
    margin-top: 0;

    .singleCtaContainer {
      display: flex;
      align-items: center;
      border: solid $az-grey-18;
      border-width: 2px 0;
      flex: 1 1 100px;

      @media #{$STARC-medium-and-up} {
        border-width: 2px;
        flex: 1;
      }

      @media #{$large-and-up} {
        height: auto;
      }

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .singleCtaInnerContainer {
        flex: 1;
        column-gap: var(--st-unit-4);
        cursor: pointer;

        @media #{$extra-large-and-up} {
          column-gap: var(--st-unit-5);
        }

        .ctaTitle {
          font-size: 18px;
          line-height: 24px;

          @media #{$STARC-medium-and-up} {
            font-size: 20px;
            line-height: 28px;
          }

          @media #{$extra-large-and-up} {
            font-size: 28px;
            line-height: 36px;
          }
        }

        .ctaDescription {
          font-size: 14px;
          line-height: 20px;

          @media #{$STARC-medium-and-up} {
            font-size: 16px;
            line-height: 24px;
          }

          @media #{$extra-large-and-up} {
            font-size: 18px;
            line-height: 26px;
          }
        }
      }
    }
  }

  .superIcon {
    width: var(--st-unit-8);
    height: var(--st-unit-8);
  }
}

.legacyBanner {
  position: relative;
  height: var(--st-unit-4);

  @media #{$STARC-medium-and-up} {
    padding-right: 0;
    padding-left: var(--st-unit-4);
    margin: 0 var(--st-unit-4) 0 0;
  }

  @media #{$extra-large-and-up} {
    padding-left: var(--st-unit-5);
  }
}

.legalContainer {
  box-sizing: border-box;
  margin-top: $az-spacing-10;
  min-height: var(--st-unit-4);

  @media #{$STARC-small-and-down} {
    margin-bottom: var(--st-unit-5);
  }
}
