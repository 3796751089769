@import '../../../theme/newVariables';

.miniCartPopup {
  @media #{$small-and-down} {
    display: block;
  }
}

.customPaperDrawer {
  min-width: $az-spacing-424;
  overflow-x: hidden;
  @media #{$small-and-down} {
    min-width: unset;
    left: 0;
  }
}

.miniCartFlyoutDialog {
  flex: 1 1 auto;
}
