@import '../../../../theme/newVariables';
@import '../../../../theme/variables';

.preFooterContainer {
  display: flex;
  padding: 32px 20px;
  width: 100%;

  @media #{$small-and-down} {
    padding: 0 $az-spacing-21 $az-spacing-s;
  }
  &:lang(en) {
    @media #{$extra-large-and-up-max} {
      padding: 2% 10%;
    }
  }
  &:lang(es) {
    @media #{$extra-large-and-up-max} {
      margin-top: $az-spacing-52;
    }
  }
}

.preFooterInnerContainer {
  display: flex;
  justify-content: flex-start;
  width: $az-percent-100;

  @media #{$medium-and-up} {
    > div {
      padding-right: $az-spacing-30;
      margin: $az-spacing-zero;
    }
  }

  @media #{$medium-and-down} {
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  @media #{$small-and-down} {
    flex-direction: column;

    > div:nth-child(odd) {
      padding-right: $az-spacing-zero;
    }
  }
}

.linkColumnContainer {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  max-width: auto;

  @media #{$medium-and-up} {
    &:not(:last-child) {
      padding-right: $az-spacing-s;
    }
  }

  @media #{$medium-and-down} {
    margin-bottom: $az-spacing-m;
  }

  @media #{$small-and-down} {
    white-space: normal;
    max-width: 95vw;
  }
}

.linkColumnTitle {
  font-family: $font-az-helvetica-heavy;
  font-size: $font-size-16;
  height: auto;
  line-height: 1.5;
  font-weight: bold;
  font-stretch: condensed;
  letter-spacing: $az-spacing-three-fourths;
  margin-bottom: $az-spacing-xxxs;
  color: $az-grey-6;

  @media #{$medium-and-down} {
    font-size: $font-size-18;
    letter-spacing: $az-spacing-point-sixty-eight;
    font-weight: 500;
  }

  @media #{$small-and-down} {
    font-size: $font-size-16;
    letter-spacing: $az-spacing-point-six;
  }
}

.linkContainer {
  margin-top: $az-spacing-xxs;
  margin-bottom: $az-spacing-5xs;
  max-width: $az-spacing-250;
}

.linkItem {
  font-family: $font-az;
  font-size: $font-size-14;
  color: $az-grey-5;

  @media #{$medium-and-down} {
    line-height: 2;
  }

  @media #{$small-and-down} {
    white-space: normal;
    line-height: normal;
  }
}

.horizontalLine {
  margin: $az-spacing-zero;
  border-style: solid;
}

.linksColumnTitleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-bottom: $az-spacing-s;

  @media #{$small-and-down} {
    margin-bottom: 0;
  }
}

.linksContainer {
  display: flex;
  flex-direction: column;
  transition: all 1s;
  height: auto;
  max-height: $az-percent-100;
}

.chevron {
  transition: transform 0.2s;
  margin-bottom: $az-spacing-26;
}

.chevronDown {
  transform: rotate(360deg);
}

.chevronUp {
  transform: rotate(180deg);
}

.showLinks {
  display: flex;
}

.hideLinks {
  max-height: $az-spacing-zero;
  display: none;
}

.allOrLess {
  font-family: $font-az-helvetica-light;
  padding-left: $az-spacing-zero;
  text-decoration: underline;
  text-transform: capitalize;
  color: $az-grey-5;
  font-size: $font-size-14;
  line-height: 2;
}

.mobileLinkContainer {
  flex: 1;
  padding-top: $az-spacing-xxs;
}

.addMargin {
  margin-top: 8px;
  margin-bottom: $az-spacing-m;

  @media #{$extra-large-and-down} {
    margin-bottom: $az-spacing-xs;
  }
}
